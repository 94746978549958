import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Title from '../components/styles/title';
// components
import Accordion from '../components/member-programs-accordion';
// styled components
import MemberProgramStyles from '../components/styles/member-program-styles';

const MemberPrograms = () => (
    <Layout>
        <SEO title="Vendor Programs" />
        <Title>Vendor Programs</Title>
        <MemberProgramStyles>
            <div className="Accordion__container MemberPrograms__container">
                <Accordion />
            </div>
            <div className="JoinNow__container">
                <p className="JoinNow__message">
                    Dedicated to building win-win relationships on behalf of all
                    members, large and small.
                </p>
                <a
                    className="JoinNow__button"
                    href="/join-now"
                    style={{ textDecoration: 'none' }}
                >
                    Join Today
                </a>
            </div>
        </MemberProgramStyles>
    </Layout>
);

export default MemberPrograms;
