import styled from 'styled-components';

const AccordionStyles = styled.div`
  .section {
    position: relative;
    width: 100%;

    button {
      position: absolute;
      right: 0;
      margin: 0;
      padding: 0;
      height: 34px;
      width: 34px;
      outline: 0;
      border: 0;
      background: none;
      text-indent: -1009999%;
      pointer-events: none;
      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 15px;
        width: 3px;
        background: ${props => props.theme.orange};
        transform-origin: 50%;
        top: 50%;
        left: 50%;
        transition: all .25s ease-in-out;
        transform: translate(150%, -50%) rotate(45deg);
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 15px;
        width: 3px;
        background: ${props => props.theme.orange};
        transform-origin: 50%;
        top: 50%;
        left: 50%;
        transition: all .25s ease-in-out;
        transform: translate(-142%, -50%) rotate(-45deg);
      }
    }
  }

  .section.open {
    button {
      &:before, &:after {
        height: 19px;
      }
      &:before {
        transform: translate(0%, -50%) rotate(-45deg);
      }
      &:after {
        transform: translate(0%, -50%) rotate(45deg);
      }
    }
    .articlewrap {
      height: 100%;
      overflow: scroll;
    }
  }

  .articlewrap {
    height: 0;
    overflow: hidden;
    transition: all .2s ease-in;
  }

  .sectionhead {
    background: ${props => props.theme.white};
    border-bottom: 1px solid ${props => props.theme.darkGray};
    color: ${props => props.theme.black};
    cursor: pointer;
    font-size: 25px;
    font-weight: 800;
    line-height: 34px;
    overflow: hidden;
    padding-bottom: 20px;
    margin-top: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    @media (max-width: 768px) { 
      font-size: 20px;
    }
  }

  .article {
    padding: 1em;
    color: #333;
    line-height: 1.3;
  }

  /* ACCORDION SECTION STYLING */

  /* fixed for Evelanta  */
  img {
    height: 77px;
    /* width: 148px;  */
    margin: 30px;
  }

  .section__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 768px) {
      justify-content: center;
      flex-direction: column;

      .memberProgram {
        align-items: center;
      }
    }
  }

  p {
    margin: 16px 0;
    font-size: 16px;
    line-height: 200%;
  }
`;

export default AccordionStyles;
