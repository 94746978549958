import styled from 'styled-components';

const MemberProgramStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .Accordion__container {
    margin: 0 auto;
    max-width: 1060px;

    @media (max-width: 768px) {
      margin: 0;
    }
  }

  .MemberPrograms__container {
    padding: 30px;
  }

  /* JOIN NOW SECTION */
  .JoinNow__container {
    align-items: center;
    background: ${props => props.theme.orange};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 20px;
  }

  .JoinNow__message {
    color: ${props => props.theme.white};
    font-size: 25px;
    font-weight: 800;
    line-height: 34px;
    margin-bottom: 32px;
    max-width: 768px;
    text-align: center;
  }

  .JoinNow__button {
    background: ${props => props.theme.white};
    border-radius: 3px;
    border: 3px solid ${props => props.theme.white};
    box-sizing: border-box;
    color: ${props => props.theme.orange};
    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
    padding: 13px 44px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.2s ease-in-out;
    z-index: 100;

    &:hover,
    &:active {
      background: transparent;
      color: ${props => props.theme.white};
    }
  }
`;

export default MemberProgramStyles;
