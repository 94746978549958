/* eslint-disable react/jsx-one-expression-per-line */

import React from 'react';
import styled from 'styled-components';
// components
import AccordionSection from './accordion-section';
// styled components
import AccordionStyles from './styles/accordion-styles';
// images
import Elevanta from '../images/elevanta.png';
import USI from '../images/usi.jpg';
import SIB from '../images/sib.png';
import EmcentrixLogo from '../images/EmCentrixLogo.jpg';
import TitaniumLogo from '../images/Titanium-logo.jpg';
// data
// import PDF from '../data/IOLCF-Snagajob-2015.pdf';

const ExternalLink = styled.a`
    font-weight: bold;
`;

const Accordion = () => (
    <AccordionStyles>
        <AccordionSection id="Titanium" title="Titanium">
            <div className="section__container">
                <a
                    href="https://0feesolutions.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img src={TitaniumLogo} alt="Titanium logo" />
                </a>
                <div>
                    <p>
                        Reduce your credit card fees through Titanium’s cash
                        discount pricing. Current LC franchisees seeing up to 2%
                        points added to their bottom lines.
                    </p>
                    <h3>Contact</h3>
                    <h4>Allan Sloat</h4>
                    <br />
                    <text>(847) 612- 1326</text>
                    <br />
                    <a href="mailto:asloatlc@aol.com">asloatlc@aol.com</a>
                </div>
            </div>
        </AccordionSection>

        <AccordionSection id="Elevanta" title="Elevanta">
            <div className="section__container">
                <a
                    href="http://www.elevantahealth.com/iolcf"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img src={Elevanta} alt="Elevanta logo" />
                </a>
                <div>
                    <p>
                        Are you looking for affordable health coverage for your
                        company so you don’t have to go unprotected? We have
                        great news for you and your employees! With Elevanta
                        Health you have access to a health care program designed
                        exclusively for franchisees. The Elevanta Health program
                        includes benefits for Little Caesars franchisees and
                        family members, as well as all classes of employees,
                        with a variety of coverage options.
                    </p>
                    <ExternalLink
                        href="https://www.elevantahealth.com/iolcf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Click Here!
                    </ExternalLink>
                </div>
            </div>
        </AccordionSection>
        <AccordionSection title="USI Insurance Services">
            <div className="section__container">
                <a
                    href="https://www.usi.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img src={USI} alt="USI logo" />
                </a>
                <div>
                    <p>Understand. Service. Innovate</p>
                    <p>
                        The IOLCF has endorsed a Property & Casualty insurance
                        program called Risk Control Solutions. USI Insurance
                        Services, Inc. is the administrator for this group
                        program. Risk Control Solutions focuses on communicating
                        Best Practices and Claims Solutions amongst
                        participants.
                    </p>
                    <p>
                        The USI insurance program provides group coverage on
                        Property, Casualty & Workers Compensation insurance.
                        Individual owners enjoy the benefit of lower premiums
                        and the assurance of reduced risk through large group
                        participation. Franchisees are seeing immediate premium
                        savings through this insurance program.
                    </p>
                    <a className="internal-link" href="/usi-request-quote">
                        <ExternalLink>Request a Quote</ExternalLink>
                    </a>
                </div>
            </div>
        </AccordionSection>
        <AccordionSection title="SIB">
            <div className="section__container">
                <a
                    href="https://www.aboutsib.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img src={SIB} alt="SIB logo" />
                </a>
                <div>
                    <p>
                        IOLCF has endorsed SIB Fixed Cost Reduction to review
                        and reduce monthly expenses for franchisees.
                    </p>
                    <p>
                        SIB offers a wide range of services to IOLCF members,
                        such as negotiating better contracts for recurring
                        monthly service expenses, and by finding and correcting
                        errors and overcharges on past bills. Through expert
                        analysis, SIB will discover if franchisees have overpaid
                        or are currently overpaying on services such as telecom,
                        utilities, waste removal, maintenance contracts, and
                        other recurring bills. IOLCF members only pay SIB a
                        percentage of the final, realized savings.
                    </p>
                    <p>
                        For more information about SIB, visit their website at:{' '}
                        <ExternalLink
                            href="https://www.aboutSIB.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            www.aboutSIB.com
                        </ExternalLink>
                    </p>
                    <p>or Contact:</p>
                    <h4>Christian Johnson</h4>
                    <p>
                        <ExternalLink href="tel:(843)606-6340">
                            (843) 606-6340
                        </ExternalLink>
                    </p>
                    <p>
                        <ExternalLink href="mailto:christian@aboutsib.com">
                            christian@aboutsib.com
                        </ExternalLink>
                    </p>
                </div>
            </div>
        </AccordionSection>
        <AccordionSection title="Emcentrix">
            <div className="section__container">
                <a
                    href="https://www.emcentrix.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img src={EmcentrixLogo} alt="SIB logo" />
                </a>
                <div>
                    <p>
                        Maintaining your employee data in filing cabinets,
                        spreadsheets, or multiple separate software systems
                        costs you in time and money. Connect your employee
                        information from end to end with EmCentrix, an
                        all-in-one HR software that consolidates your employee
                        data, applicant tracking, onboarding, time clocks, time
                        sheets, time off, benefits, performance management,
                        employee files, and more, all in a single platform that
                        integrates with payroll and POS systems. All of this
                        information is shared and updated throughout the
                        enterprise online, without the need for redundant data
                        entry, streamlining your HR & payroll processes and
                        saving you time and money.
                    </p>
                    <p>
                        EmCentrix can integrate with your existing vendors, and
                        have created a partnership with Altametrics to connect
                        your HR data with your POS, providing an enterprise-wide
                        solution that is both powerful and simple.
                    </p>
                    <p>Contact: Sales</p>
                    <p>
                        <ExternalLink href="tel:(818)-995-2495">
                            p: 818-995-2495 ext. 101
                        </ExternalLink>
                    </p>
                    <p>
                        <ExternalLink href="mailto:sales@emcentrix.com">
                            e: sales@emcentrix.com
                        </ExternalLink>
                    </p>
                    <ExternalLink
                        href="https://www.emcentrix.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.emcentrix.com
                    </ExternalLink>
                </div>
            </div>
        </AccordionSection>
    </AccordionStyles>
);

export default Accordion;
