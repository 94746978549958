/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// styled components
import AccordionStyles from './styles/accordion-styles';

class AccordionSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      classes: 'section',
    };
  }

  handleClick = () => {
    const { open } = this.state;

    if (open) {
      this.setState({
        open: false,
        classes: 'section',
      });
    } else {
      this.setState({
        open: true,
        classes: 'section open',
      });
    }
  }

  render() {
    const { classes } = this.state;
    const {
      children,
      title,
    } = this.props;

    return (
      <AccordionStyles className={classes}>
        <button type="button">toggle</button>
        <div
          type="button"
          className="sectionhead"
          onClick={this.handleClick}
        >
          {title}
        </div>
        <div className="articlewrap">
          <div className="article">
            {children}
          </div>
        </div>
      </AccordionStyles>
    );
  }
}

AccordionSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default AccordionSection;
